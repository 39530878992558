var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InvioceInfo" },
    [
      _c("h3", [_vm._v("发票信息")]),
      _c(
        "el-button",
        {
          staticClass: "addInvoice",
          attrs: { type: "primary", disabled: 20 - _vm.totalPage == 0 },
          on: {
            click: function($event) {
              return _vm.invoiceEdit()
            }
          }
        },
        [_vm._v("新增发票信息")]
      ),
      _c("span", [
        _vm._v(
          "最多可保存20个，你还可以添加" + _vm._s(20 - _vm.totalPage) + "个"
        )
      ]),
      _vm._l(_vm.invoiceData, function(item, index) {
        return _c("el-card", { key: index, staticClass: "box-card" }, [
          item.ifVat == 1 ? _c("h4", [_vm._v("增值普通发票信息")]) : _vm._e(),
          item.ifVat == 2 ? _c("h4", [_vm._v("增值专用发票信息")]) : _vm._e(),
          item.ifVat == 3 ? _c("h4", [_vm._v("个人发票信息")]) : _vm._e(),
          item.ifVat == 3
            ? _c("div", [
                _c("p", [_vm._v("发票抬头：" + _vm._s(item.vatInvoiceTitle))])
              ])
            : _c("div", [
                _c("p", [_vm._v("单位名称：" + _vm._s(item.vatInvoiceTitle))]),
                _c("p", [
                  _vm._v("纳税人识别码：" + _vm._s(item.vatTaxpayerNum))
                ]),
                item.ifVat == 2
                  ? _c("div", [
                      _c("p", [
                        _vm._v("注册地址：" + _vm._s(item.vatCompanyAddress))
                      ]),
                      _c("p", [
                        _vm._v("注册电话：" + _vm._s(item.vatTelphone))
                      ]),
                      _c("p", [
                        _vm._v("开户银行：" + _vm._s(item.vatBankName))
                      ]),
                      _c("p", [
                        _vm._v("银行账户：" + _vm._s(item.vatBankAccount))
                      ])
                    ])
                  : _vm._e()
              ]),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              item.ifDefault != 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.setDefault(item)
                        }
                      }
                    },
                    [_vm._v("设为默认")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function($event) {
                      _vm.invoiceEdit(item.id.toString())
                    }
                  }
                },
                [_vm._v("编辑")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function($event) {
                      _vm.invoiceDelete(item.id.toString())
                    }
                  }
                },
                [_vm._v("删除")]
              )
            ],
            1
          )
        ])
      }),
      _c("el-pagination", {
        staticClass: "page",
        attrs: {
          "current-page": _vm.page,
          "page-sizes": [10, 20, 50, 100],
          "page-size": _vm.limit,
          total: _vm.totalPage,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "size-change": _vm.sizeChangeHandle,
          "current-change": _vm.currentChangeHandle
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.type == "add" ? "添加发票" : "编辑发票",
            visible: _vm.showAddInvoice,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showAddInvoice = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "120px",
                model: _vm.form,
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发票类型", prop: "ifVat" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.ifVatType },
                      model: {
                        value: _vm.form.ifVat,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "ifVat", $$v)
                        },
                        expression: "form.ifVat"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("增值普通发票")
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("增值专用发票")
                      ]),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("个人")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.form.ifVat == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "抬头名称", prop: "vatInvoiceTitle" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30", "show-word-limit": "" },
                        model: {
                          value: _vm.form.vatInvoiceTitle,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatInvoiceTitle", $$v)
                          },
                          expression: "form.vatInvoiceTitle"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "公司名称", prop: "vatInvoiceTitle" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30", "show-word-limit": "" },
                        model: {
                          value: _vm.form.vatInvoiceTitle,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatInvoiceTitle", $$v)
                          },
                          expression: "form.vatInvoiceTitle"
                        }
                      })
                    ],
                    1
                  ),
              _vm.form.ifVat != 3
                ? _c(
                    "el-form-item",
                    {
                      attrs: { label: "纳税人识别号", prop: "vatTaxpayerNum" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.vatTaxpayerNum,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatTaxpayerNum", $$v)
                          },
                          expression: "form.vatTaxpayerNum"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.ifVat == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "注册地址", prop: "vatCompanyAddress" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "100", "show-word-limit": "" },
                        model: {
                          value: _vm.form.vatCompanyAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatCompanyAddress", $$v)
                          },
                          expression: "form.vatCompanyAddress"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.ifVat == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "注册电话", prop: "vatTelphone" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.vatTelphone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatTelphone", $$v)
                          },
                          expression: "form.vatTelphone"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.ifVat == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "开户银行", prop: "vatBankName" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30", "show-word-limit": "" },
                        model: {
                          value: _vm.form.vatBankName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatBankName", $$v)
                          },
                          expression: "form.vatBankName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.ifVat == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "银行账户", prop: "vatBankAccount" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.vatBankAccount,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatBankAccount", $$v)
                          },
                          expression: "form.vatBankAccount"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showAddInvoice = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("立即提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }