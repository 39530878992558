<template>
  <div class='InvioceInfo'>
    <h3>发票信息</h3>
    <el-button type="primary" @click="invoiceEdit()" class="addInvoice" :disabled="20 - totalPage == 0">新增发票信息</el-button>
    <span>最多可保存20个，你还可以添加{{ 20 - totalPage }}个</span>
    <el-card class="box-card" v-for="(item, index) in invoiceData" :key="index">
      <h4 v-if="item.ifVat == 1">增值普通发票信息</h4>
      <h4 v-if="item.ifVat == 2">增值专用发票信息</h4>
      <h4 v-if="item.ifVat == 3">个人发票信息</h4>
      <div v-if="item.ifVat == 3">
        <p>发票抬头：{{ item.vatInvoiceTitle }}</p>
      </div>
      <div v-else>
        <p>单位名称：{{ item.vatInvoiceTitle }}</p>
        <p>纳税人识别码：{{ item.vatTaxpayerNum }}</p>
        <div v-if="item.ifVat == 2">
          <p>注册地址：{{ item.vatCompanyAddress }}</p>
          <p>注册电话：{{ item.vatTelphone }}</p>
          <p>开户银行：{{ item.vatBankName }}</p>
          <p>银行账户：{{ item.vatBankAccount }}</p>
        </div>
      </div>
      <div style="text-align: right">
        <el-button type="text" @click="setDefault(item)" v-if="item.ifDefault != 1">设为默认</el-button>
        <el-button type="text" @click="invoiceEdit(item.id.toString())">编辑</el-button>
        <el-button type="text" @click="invoiceDelete(item.id.toString())">删除</el-button>
      </div>
    </el-card>
    <el-pagination
      class="page"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="limit"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>

    <el-dialog
      :title="type == 'add' ? '添加发票' : '编辑发票' "
      :visible.sync="showAddInvoice"
      width="30%">
      <el-form label-width="120px" ref="form" :model="form" :rules="rules">
        <el-form-item label="发票类型" prop="ifVat">
          <el-radio-group v-model="form.ifVat" @change="ifVatType">
            <el-radio :label="1">增值普通发票</el-radio>
            <el-radio :label="2">增值专用发票</el-radio>
            <el-radio :label="3">个人</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="抬头名称" v-if="form.ifVat == 3" prop="vatInvoiceTitle">
          <el-input v-model="form.vatInvoiceTitle" maxlength="30" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="公司名称" v-else prop="vatInvoiceTitle">
          <el-input v-model="form.vatInvoiceTitle" maxlength="30" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号" v-if="form.ifVat != 3" prop="vatTaxpayerNum">
          <el-input v-model="form.vatTaxpayerNum"></el-input>
        </el-form-item>
          <el-form-item label="注册地址" v-if="form.ifVat == 2" prop="vatCompanyAddress">
          <el-input v-model="form.vatCompanyAddress" maxlength="100" show-word-limit></el-input>
        </el-form-item>
          <el-form-item label="注册电话" v-if="form.ifVat == 2" prop="vatTelphone">
          <el-input v-model="form.vatTelphone"></el-input>
        </el-form-item>
          <el-form-item label="开户银行" v-if="form.ifVat == 2" prop="vatBankName">
          <el-input v-model="form.vatBankName" maxlength="30" show-word-limit></el-input>
        </el-form-item>
          <el-form-item label="银行账户" v-if="form.ifVat == 2" prop="vatBankAccount">
          <el-input v-model="form.vatBankAccount"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddInvoice = false">取 消</el-button>
        <el-button type="primary" @click="submit">立即提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
import { getInvoiceInfo, saveInvoiceInfo, setDefault, updateInvoice, deleteInvoice, getEditInfo } from '@/api/userInfo'

export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入注册电话'));
      } else {
        var reg1=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        var reg2=/^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})$/;
        var reg3=/^[2-9][0-9]{6,7}$/;
        if (reg1.test(value) || reg2.test(value) || reg3.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的注册电话'));
        }
      }
    }
    var validateAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入银行账户'));
      }else if(value.length > 30) {
        callback(new Error('请输入正确的银行账户'))
      } else {
        var reg=/^\d+$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的银行账户'));
        }
      }
    }
    var validateNum = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入纳税人识别号'));
      } else if(value.length > 30) {
        callback(new Error('请输入正确的纳税人识别号'))
      } else {
        var reg=/^[\d\w]+$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的纳税人识别号'));
        }
      }
    }
    return {
      showAddInvoice:false,
      page: 1,
      limit: 10,
      totalPage: 0,
      invoiceData: [],
      type: '',
      form: {
        ifVat: 1,
        vatTaxpayerNum: '',
        vatInvoiceTitle: '',
        vatCompanyAddress: '',
        vatTelphone: '',
        vatBankName: '',
        vatBankAccount: '',
      },
      rules: {
        ifVat: [
          { required: true, message: '请选择发票类型', trigger: 'change' },
        ],
        vatInvoiceTitle: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        vatTaxpayerNum: [
          { required: true, validator: validateNum, trigger: 'blur' },
        ],
        vatCompanyAddress: [
          { required: true, message: '请输入注册地址', trigger: 'blur' },
        ],
        vatTelphone: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        vatBankName: [
          { required: true, message: '请输入开户银行', trigger: 'blur' },
        ],
        vatBankAccount: [
          { required: true, validator: validateAccount, trigger: 'blur' },
        ],
      }
    }
  },
  created () {
    this.getInvoiceList()
  },
  watch: {
    showAddInvoice(newVal) {
      if (!newVal) {
        this.form = {
          ifVat: 1,
          vatTaxpayerNum: '',
          vatInvoiceTitle: '',
          vatCompanyAddress: '',
          vatTelphone: '',
          vatBankName: '',
          vatBankAccount: '',
        }
        if(this.form.id) {
          this.form.id = ''
        }
        this.$refs.form.resetFields()
      }
    },
  },
  methods: {
    ifVatType () {
      this.$refs.form.clearValidate()
    },
    async getInvoiceList () {
      const res = await getInvoiceInfo({limit: this.limit, page: this.page})
      if(res.code == 0) {
        this.invoiceData = res.data.list
        this.totalPage = res.data.totalCount
      }
    },
    // 每页数
    sizeChangeHandle (val) {
      this.limit = val
      this.page = 1
      this.getInvoiceList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.page = val
      this.getInvoiceList()
    },
    // 提交
    submit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res
          if(this.form.id) {
            res = await updateInvoice(this.form)
          } else {
            res = await saveInvoiceInfo(this.form)
          }
          if(res.code == 0) {
            this.sizeChangeHandle()
            this.$message.success('提交成功')
            this.showAddInvoice = false
          }
        }
      })
    },
    // 设为默认
    async setDefault (data) {
      const res = await setDefault({id: data.id.toString(), ifDefault: 1})
      if(res.code == 0) {
        this.$message.success('设置成功')
        this.sizeChangeHandle()
      }
    },
    // 新增/编辑
    async invoiceEdit (id) {
      if(id) {
        this.type = 'edit'
        const res = await getEditInfo({id})
        if(res.code == 0) {
          this.form = res.data
          this.form.id = id
        }
      } else {
        this.type = 'add'
      }
      this.showAddInvoice = true
    },
    // 删除
    invoiceDelete (id) {
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async () => {
        const res = await deleteInvoice([id])
        if (res.code === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.sizeChangeHandle()
            }
          })
        }
      }).catch(() => {})
    },
  }
}
</script>

<style lang="scss" scoped>
.InvioceInfo{
  background-color: white;
  padding: 30px;
  .addInvoice {
    margin: 30px 30px 0 0;
  }
}
.box-card{
  width: 50%;
  margin-top: 20px;
  font-size: 14px;
  p {
    line-height: 30px;
  }
}
.page {
  margin-top: 50px;
  text-align: right;
}
</style>